import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_isf3yqghluwc2ukikpstckskjq/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7._w367bmdhtgrgy63gkt7gwwa7xe/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}